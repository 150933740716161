import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

const PrivacyPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Datenschutzerklärung</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <p>
            Salzburg University of Applied Sciences (SUAS) takes the careful
            processing and protection of your personal data very seriously. We
            keep data entrusted to us strictly confidential and handle them
            responsibly. In view of the provisions of the EU General Data
            Protection Regulation and the Austrian Data Protection Act in
            particular, we view data protection as a topic of social relevance
            across Europe and as an indication of quality.
            {'\n\n'}
            Based on the relevant data protection provisions, we hereby inform
            you about the data processing related to this website, its purpose
            and your rights.
            {'\n\n'}
            This Privacy Policy refers to data protection law as amended from
            time to time. We expressly reserve the right to make amendments or
            adjustments in the future. We therefore recommend that you visit
            this section of our website regularly to stay updated about any
            changes to the processing of personal data.
            {'\n\n'}
            Controller for data processing related to the barcamp-sbg.at website
            that you are currently visiting:
            {'\n\n'}
            Fachhochschule Salzburg GmbH (Salzburg University of Applied
            Sciences) {'\n'}
            Urstein Süd 1, 5412 Puch/Salzburg, Austria {'\n'}
            Telephone: +43-50-2211-0 {'\n'}
            Email: office.mmt@fh-salzburg.ac.at
          </p>

          <h2>Processing of Personal Data</h2>
          <h3>Website</h3>

          <p>
            When visiting this website, the web server temporarily saves each
            access in a log file. This data is collected and processed for the
            purpose of enabling use of the website (establishing a connection),
            ensuring system security and stability over the long term, enabling
            technical administration of the network infrastructure and
            optimisation of our Internet offering, and for internal statistical
            purposes. The IP address is statistically evaluated in the event of
            attacks on our network infrastructure to block the attack and for
            this purpose.
            {'\n\n'}
            The following information is collected without further action during
            the visit and stored until automated deletion after 30 days:{'\n'}-
            the IP address of the requesting computer;{'\n'}- the date and time
            of access;{'\n'}- the name and URL of the retrieved file;{'\n'}- the
            website from which the access is made;{'\n'}
            {'\n\n'}
            Data collection on our website{'\n'}- Server log files{'\n'}
            The provider of the pages automatically collects and stores
            information in so-called server log files, which your browser
            automatically transmits to us. {'\n\n'} These are: - Browser type
            and browser version{'\n'}- used operating system{'\n'}- Referrer URL
            {'\n'}- Hostname of the accessing computer{'\n'}- Time of the server
            request{'\n'}- IP address{'\n'}
            {'\n'}
            This data will not be merged with other data sources. This data is
            collected on the basis of Art. 6 para. 1 lit. f DSGVO. The website
            operator has a justified interest in the technically error-free
            presentation and optimisation of his website - for this purpose the
            server log files must be recorded.
            {'\n\n'}
            Google Web Fonts{'\n'}
            This page uses so-called web fonts provided by Google for the
            uniform display of fonts. When you call up a page, your browser
            loads the required web fonts into its browser cache in order to
            display texts and fonts correctly. For this purpose, the browser you
            are using must connect to Google's servers. This enables Google to
            know that your IP address has been used to access our website. The
            use of Google Web Fonts is in the interest of a uniform and
            appealing presentation of our online services. This constitutes a
            legitimate interest within the meaning of Art. 6 para. 1 lit. f
            DSGVO. If your browser does not support web fonts, a standard font
            will be used by your computer. Should you wish to contact us or our
            staff by email, please note that the confidentiality of the
            information you send cannot be guaranteed. Due to the technical
            nature of email, the content of email may be accessed by third
            parties, unless specific technical security measures are taken.
          </p>
          {'\n\n'}
          <h3>Photo & Video Policy</h3>
          <p>
            It is pointed out that the University of Applied Science Salzburg,
            as the client responsible for the documentation and publication of
            the event and its contents, will make photo and video recordings,
            including sound, during the conference out of a predominant
            interest.
            {'\n\n'}
            By participating in the event, this is acknowledged and the Salzburg
            University of Applied Sciences is granted the right free of charge
            to use the above-mentioned video, image and/or sound recordings
            temporally, locally and in terms of content without restriction, in
            particular for the following purposes:
            {'\n\n'}- to publish and disseminate them on the University's own
            public website and on that of the event{'\n'}- for internal purposes
            and for PR or marketing purposes, i.e. the effective presentation of
            our offers, projects and services as an FH, including film
            screenings at internal or public company events and trade fairs, as
            well as on the Internet, intranet or in internal and external
            communication systems.{'\n'}- Passing on of photo and video
            recordings including sound in press releases.{'\n'}
            {'\n\n'}
            This data is also processed for the purposes of processing and
            publication by service providers for creation, image processing and
            publication. {'\n'}
            The participant waives the right to name, but agrees to a possible
            naming of the name.{'\n'}
            {'\n\n'}
            All photos and film contributions will be kept for up to one year
            after the end of the congress. A selection of photos and film
            material will be kept together with the programme and presentations
            in the overwhelming legitimate interest of the University of Applied
            Sciences for the future presentation of its history and milestones
            in the context of its activities for the duration of the University
            of Applied Sciences.
            {'\n\n'}
            Compulsorily participating students as well as all other
            participants of the congress can make use of the right to prohibit
            the organizer from taking photos or filming their own person without
            any disadvantages. If this is the case, please report this to the
            Check-In on the day of the event. Then the appropriate measures will
            be taken there.
          </p>
          <Link to="/">Back to Main page</Link>
        </div>
      </section>
    </article>
  </Layout>
);

export default PrivacyPage;
